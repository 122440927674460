import React from "react";
import { useStore } from "../../store/use-store";
import styled from "styled-components";
import moment from "moment";
import { media } from "../../utils/breakpoints";
import { observer } from 'mobx-react';
import { formatAsset } from '@deltalabs/eos-utils';
import { dec2asset } from '../../utils/format';
import { FiExternalLink } from "react-icons/all";
import {
  ResponsiveFlex,
  BlueButton,
  HorizontalFlex,
} from '../shared';

const SwapLink2 = styled.a`
  font-size: 14px;
`;

const Wrapper = styled.div`
  margin-bottom: 32px;
  margin-top: 32px;
  width: 100%;
  padding: 16px;
  background-color: ${p => p.theme.colors.bgLight};
  font-size: 14px;
  font-weight: 300;
  border: 1px solid ${p => p.theme.colors.bgLightest};
  border-radius: ${p => p.theme.borderRadius2x};
`;
const SwapLiqWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 16px;
  margin-top: auto;
`;

const SwapLink = styled.a`
  font-family: inherit;
  display: flex;
  color: ${p => p.theme.colors.white};
  align-items: center;
  font-size: 12px;
  line-height: 10px;
  text-align: center;
  padding: 8px 12px;
  border-radius: ${p => p.theme.borderRadius};
  cursor: pointer;
  border: 1px solid ${p => p.theme.colors.white};
  margin-right: 16px;
  
  > svg {
    color: ${p => p.theme.colors.white};
    margin-right: 4px;
    top: -1px;
    position: relative;
  }
  
  :hover,
  :active,
  :focus {
    color: ${p => p.theme.colors.whiteDarkened};
    border-color: ${p => p.theme.colors.whiteDarkened};

    > svg {
      color: ${p => p.theme.colors.whiteDarkened};
    }
  }
`;

const Block = styled.div`
  flex-shrink: 0;
  white-space: nowrap;
  
  ${media.lessThan('sm')} {
    width: 50%;
    margin-bottom: 24px;
  }
  
  ${media.lessThan(`xs`)} {
    margin-bottom: 0;
    width: 100%;
  }
`;

const LimitFieldsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 4px;
  margin-bottom: 4px;
`;

const LimitsField = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-wrap: wrap;
  
  :not(:last-child) {
    margin-right: 8px;
  }
`;

const LimitsFieldLabel = styled.div`
  margin-right: 4px;
  font-size: 11px;
  color: ${p => p.theme.colors.light};
`;

const LimitsFieldValue = styled.div`
  color: ${p => p.theme.colors.whiteDarkened};
`;

const LimitsTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
`;

const LimitsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  
  ${media.lessThan('smd')} {
    width: 100%;
  }
`;

const LimitsColumn = styled.div`
  flex-shrink: 0;
  :not(:last-child) {
    margin-right: 32px;
  }

  ${media.lessThan('smd')} {
    width: 100%;
  }
`;

const LimitsColumnTitle = styled.div`
  margin-right: 12px;
  margin-bottom: 4px;
  margin-top: 4px;
  
  ${media.lessThan('smd')} {
    width: 100%;
  }
`;

const usdIntWithSeparators = (val:number) => {
  return formatAsset(dec2asset(Math.round(val), { code: 'USD', precision: 2 }), { separateThousands: true, withSymbol: false }).slice(0, -3)
}

const LimitsInfo: React.FC<{}> = () => {
  const [ vigorStore ] = useStore((store) => [ store.vigorStore ]);

  const usedAccountValue = usdIntWithSeparators(vigorStore.userWithdrawLimits.usedAccountValue);
  const allowedAccountValue = usdIntWithSeparators(vigorStore.userWithdrawLimits.allowedAccountValue);
  const perTxAllowed = usdIntWithSeparators(vigorStore.userWithdrawLimits.perTxAllowed);
  const available = usdIntWithSeparators(vigorStore.userWithdrawLimits.available);
  const used = usdIntWithSeparators(vigorStore.userWithdrawLimits.used);
  const totalAllowed = usdIntWithSeparators(vigorStore.userWithdrawLimits.totalAllowed);

  return (
    <Wrapper>

<LimitsTitle>News</LimitsTitle>

   
      <HorizontalFlex justifyContent="space-between" style={{ flexWrap: 'wrap' }}>
      <Block>
      
      <LimitsColumn>
      <LimitsRow>
          <LimitFieldsWrap>
                
                  <SwapLink2 rel="noreferrer noopener" target="_blank" href='https://twitter.com/0riginV/status/1518951886262607872'>
                    <FiExternalLink size={11}/>
                    { " 0rigin boosts developement of Vigor 2.0" }
                  </SwapLink2>
              
          </LimitFieldsWrap>
      </LimitsRow>
      </LimitsColumn>
        </Block>

        </HorizontalFlex>
    </Wrapper>
  );
};

export default observer(LimitsInfo);
